import React,{ useEffect } from 'react'
import $ from 'jquery';
import InstagramEmbed from 'react-instagram-embed';



function Header() {
	 useEffect(()=>{
    
      //SubMenu Dropdown Toggle
if ($('.menu-area li.menu-item-has-children ul').length) {
	$('.menu-area .navigation li.menu-item-has-children').append('<div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>');

}

//Mobile Nav Hide Show
if ($('.mobile-menu').length) {

	var mobileMenuContent = $('.menu-area .main-menu').html();
	$('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

	//Dropdown Button
	$('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
		$(this).toggleClass('open');
		$(this).prev('ul').slideToggle(500);
	});
	//Menu Toggle Btn
	$('.mobile-nav-toggler').on('click', function () {
		$('body').addClass('mobile-menu-visible');
	});

	//Menu Toggle Btn
	$('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
		$('body').removeClass('mobile-menu-visible');
	});



}
      $(".menu-tigger").on("click", function () {
	$(".extra-info,.offcanvas-overly").addClass("active");
	return false;
});
$(".menu-close,.offcanvas-overly").on("click", function () {
	$(".extra-info,.offcanvas-overly").removeClass("active");
});

  },[]);
  return (

	

	 <header>
   <div className="header-top-area">
      <div className="container">
        <div className="row ">
          <div className="col-lg-6 m-auto">
            <div className="header-social justify-content-center">
              <span>Follow us on :</span>
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/metarealfights/">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/MetaRealFights">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://discord.gg/u3e2BF5jTs">
                      <i className="fab fa-discord"></i>
                    </a>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

        <div id="sticky-header" className="menu-area transparent-header">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler"><img src="assets/img/icon/menu.png" alt="" /></div>
                <div className="menu-wrap">
                  <nav className="menu-nav show">
                    <div className="logo">
                      <a href="/"><img src="assets/img/logo/logo3.png" alt="" /></a>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-xl-flex">
                      <ul className="navigation">
                        <li className="active"><a href="/#banner">Club</a></li>
                        <li><a href="/#game">Game</a></li>
                        <li><a href="/#tokenomics">Tokenomics</a></li>
                        <li><a href="/#roadmap">ROADMAP</a></li>
                        <li><a href="/#faq">FAQ</a></li>
                        <li><a href="/#contact">CONTACT</a></li>
                      </ul>
                    </div>
                    <div className="header-action">
                      <ul>
  
                        <li className="header-th"><a href="/#" className="menu-tigger"><img src="assets/img/icon/menu.png" alt="" /></a></li>
                        <li className="header-phone">
                          <div className="phone-icon"><i className="fa-brands fa-discord" /></div>
                          <div className="call"><span>Join Team:</span><a href="https://discord.gg/u3e2BF5jTs" target="_blank" rel="noreferrer">Discord</a></div>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                {/* Mobile Menu  */}
                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn"><i className="fa-solid fa-xmark" /></div>
                    <div className="nav-logo"><a href="/"><img src="assets/img/logo/logo2.png" alt="" /></a>
                    </div>
                    <div className="menu-outer">
                      {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                    </div>
                    <div className="header-phone">
                      <div className="phone-icon"><i className="fa-brands fa-discord" /></div>
                      <div className="call"><span>Forums:</span><a href="/#" rel="noreferrer" target="_blank">Discord</a></div>
                    </div>
                    <div className="social-links">
                      <ul className="clearfix">
                        <li><a href="https://twitter.com/MetaRealFights"><i className="fa-brands fa-twitter" /></a></li>
                        <li><a href="https://discord.gg/u3e2BF5jTs"><i className="fa-brands fa-discord" /></a></li>
                        <li><a href="https://www.instagram.com/metarealfights"><i className="fa-brands fa-instagram" /></a></li>
                        <li><a href="https://www.youtube.com/channel/UCXBuBmonTPgXZcjiLWBkmdg"><i className="fa-brands fa-youtube" /></a></li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" />
                {/* End Mobile Menu */}
              </div>
            </div>
          </div>
        </div>

        <div className="extra-info">
          <div className="close-icon menu-close">
            <button><i className="fa-regular fa-rectangle-xmark" /></button>
          </div>
          <div className="side-logo mb-30">
            <a href="/"><img src="assets/img/logo/logo3.png" alt="Logo" /></a>
          </div>
          <div className="side-info mb-30">
            <div className="contact-list mb-30">
              <h4>Instagram</h4>
              <p><a target="_blank" rel="noreferrer" href="https://www.instagram.com/metarealfights/">@Metarealfights</a><br/></p>
            </div>
            <div className="contact-list mb-30">
              <h4>Twitter</h4>
              <p><a target="_blank" rel="noreferrer" href="https://twitter.com/MetaRealFights">@Metarealfights</a></p>
            </div>
            <div className="contact-list mb-30">
              <h4>Discord</h4>
              <p><a target="_blank" rel="noreferrer" href="https://discord.gg/u3e2BF5jTs">@Metarealfights</a></p>
            </div>
            <div className="contact-list mb-30">
              <h4>Youtube</h4>
              <p><a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCXBuBmonTPgXZcjiLWBkmdg/">@Metarealfights</a></p>
            </div>
          </div>
          <ul className="side-instagram">
            <li><a href="/#"><img src="assets/img/images/nftcard.png" alt="" /></a></li>
            <li><a href="/#"><img src="assets/img/images/nftcard.png" alt="" /></a></li>
            <li><a href="/#"><img src="assets/img/images/nftcard.png" alt="" /></a></li>
          </ul>
          <div className="social-icon-right text-center mt-30">
            <a href="/#"><i className="fa-brands fa-instagram" /></a>
            <a href="/#"><i className="fa-brands fa-twitter" /></a>
            <a href="/#"><i className="fa-brands fa-discord" /></a>
            <a href="/#"><i className="fa-brands fa-youtube" /></a>
          </div>
        </div>
        <div className="offcanvas-overly" />
        {/* offCanvas-menu-end */}
      </header>
  )
}

export default Header