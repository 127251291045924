import React,{ useState } from 'react'



function PurchaseArea() {
    const [copySucessUniswap,setcopySucessUniswap]= useState()
   const [copySuccessPancake,setcopySuccessPancake] = useState()
   const copyToClipboard = (token, text)=>{
        if(navigator.clipboard !== 'undefined'){
            navigator.clipboard.writeText(token)
            .then(() =>{
                if( text === 'uniswap' ){
                    setcopySucessUniswap('Copied!');
                }else{
                    setcopySuccessPancake('Copied!');
                }
            })
            .catch((error) => {
                console.error(error)
            })
        }
    }
  return (
	<section className="purchase-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title text-center mb-50">
                <h2 className="title">YOU CAN <span>PURCHASE</span></h2>
                <h5 className="sub-title">NOW ON OUR WEBSITE</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="purchase-title-paragraph text-center">
                <p>The best way to buy the MRF token. Be sure to copy and paste the correct contract below when adding the token to your ETH wallet or BSC wallet. When you buy MRF on Uniswap or Pancake Swap.</p>
              </div>
              <div className="purchase-item active">
                <div className="purchase-btn">
                  <a href="https://token.metarealfights.com" target="_blank" className="btn">Buy Token</a>
                </div>
                <p>Don't send it to this address. Just copy paste it and Insert into pancakeswap or uniswap. Otherwise you will loose your tokens.</p>
                <h4 className="title">0x97Fc2ED08E81d55f69F1eE219DB50ad764870bAc<i onClick={()=>copyToClipboard("0x97Fc2ED08E81d55f69F1eE219DB50ad764870bAc","uniswap")} className="fa-solid fa-copy"></i> <span>{copySucessUniswap}</span> </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="purchase-img-shape"><img src="assets/img/images/purchase_shape001m.png" className="wow fadeInLeftBig" data-wow-delay=".3s" data-wow-duration="2s" alt="" /></div>
        <div className="purchase-img-shape right-shape"><img src="assets/img/images/purchase_shape02m.png" className="wow fadeInRightBig" data-wow-delay=".3s" data-wow-duration="2s" alt="" /></div>
      </section>
  )
}

export default PurchaseArea