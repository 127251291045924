import React from 'react'

function Token() {
  return (
<section id="tokenomics"className=" pt-115 pb-120">
        <div className="container">
          <div className="tokenomics-box">
            <div className="row align-items-end token-box-wrap">
              <div className="col-lg-6">
                <div className="token-content mb-65">
                  <div className="token-icon mb-20">
                    <img src="assets/img/icon/token_icon.png" alt="" />
                  </div>
                  <h2 className="title">TOKENOMICS</h2>
                  <p>The $MRF token, a one-of-a-kind token, is what powers the MetaRealFights platform, giving our ecosystem real utility and providing automatic rewards systems.</p>
                  <a href="/#token-allocations" rel="noreferrer">Check Allocations</a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="token-item mb-20">
                  <div className="token-content-wrap">
                    <h2 className="title">Total <span>Supply</span></h2>
                  </div>
                  <ul className="token-list">
                    <li>1,000,000,000 MRF</li>
                    <li className='tokenomics-desc'>With a reasonable total supply of one billion $MRF, we're making sure that your token will carry lasting value.</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="token-item mb-20">
                  <div className="token-content-wrap">
                    <h2 className="title">Powered <span>by</span></h2>
                  </div>
                  <ul className="token-list">
                    <li>Ethereum Blockchain</li>
                    <li className='tokenomics-desc'>We put the blockchain to work for you, using the latest developments in smart contracts to bring you the maximum value for your investment.</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="token-item mb-20">
                  <div className="token-content-wrap">
                    <h2 className="title">Passive <span>Income</span></h2>
                  </div>
                  <ul className="token-list">
                    <li>Staking and LP Rewards</li>
                    <li className='tokenomics-desc'>A substantial portion of the $MRF token has been delegated for staking and liquidity farming rewards bringing stability to the token economy.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
</section>

  )
}
export default Token