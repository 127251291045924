import React,{useEffect} from 'react'
import { gsap } from "gsap";
import {  ScrollTrigger } from "gsap/ScrollTrigger";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaBeer } from 'react-icons/fa';
import { GiHighPunch, GiPunch } from "react-icons/gi";

function Roadmap2() {
    return (
    <section id="roadmap" className="roadmap-area">
        <VerticalTimeline>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#FF901B', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #FF901B' }}
            date="Q1 2022"
            iconStyle={{ background: '#FF901B', color: '#fff', textAlign: 'center', verticalAlign: 'middle',}}
            /*icon={<SchoolIcon />}*/
            icon={<GiPunch />}
        >
            <h3 className="vertical-timeline-element-title">LAUNCH PHASE 1</h3>
            <h4 className="vertical-timeline-element-subtitle">2022</h4>
            <br />
            <div className="timeline-list">
            <ul>
                <li className='wtext'>Website Launch</li>
                <li className='wtext'>Social Media Launch</li>
                <li className='wtext'>Whitepaper launch</li>
                <li className='wtext'>Official Audit </li>
                <li className='wtext'>Token Creation </li>
                <li className='wtext'>IGO Presale</li>
            </ul>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Q2 2023"
            iconStyle={{ background: '#FF901B', color: '#fff' }}
            icon={<GiPunch />}
            
        >
            <h3 className="vertical-timeline-element-title btitle">ONCE PRESALE COMPLETED</h3>
            <h4 className="vertical-timeline-element-subtitle btitle">Q2 2023</h4>
            <br />
            <div className="timeline-list">
            <ul>
                <li>IGO Public launch</li>
                <li>Game Trailer </li>
                <li>Beta-Game</li>
                <li>3D NFT collections</li>
                <li>CEX listing</li>
                <li>Partnership Coop </li>
                <li>Several Updates</li>
            </ul>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Q3 2023"
            iconStyle={{ background: '#FF901B', color: '#fff' }}
            icon={<GiHighPunch />}
            
        >
            <h3 className="vertical-timeline-element-title btitle">PHASE 3</h3>
            <h4 className="vertical-timeline-element-subtitle btitle">Q3 2023</h4>
            <br />
            <div className="timeline-list">
                <ul>
                    <li>NFT-Marketplace Development</li>
                    <li>CEX Listing</li>
                    <li>MRF App launch</li>
                    <li>Expand Marketing Strategy</li>
                    <li>Influencer Marketing</li>
                    <li>Big game trailer</li>
                    <li>VR Game-Beta</li>
                </ul>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2023 - 2024"
            iconStyle={{ background: '#FF901B', color: '#fff' }}
            icon={<GiHighPunch />}
            
        >
            <h3 className="vertical-timeline-element-title btitle">PHASE 4</h3>
            <h4 className="vertical-timeline-element-subtitle btitle">Q4 2024</h4>
            <br />
            <div className="timeline-list">
                <ul>
                    <li>Big Rewards</li>
                    <li>3D Arena NFT </li>
                    <li>More Listings</li>
                    <li>More Cooperations</li>
                </ul>
            </div>
        </VerticalTimelineElement>





        </VerticalTimeline>
    </section>
    )}
    export default Roadmap2