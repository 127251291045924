import React from 'react'

function Footer() {
  return (
	<footer>
        <div className="footer-top-wrap">
          <div className="container footer-container">
            <div className="footer-menu-wrap">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <div className="f-logo">
                    <a href="/#"><img src="assets/img/logo/logo3.png" alt="" /></a>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="footer-menu">
                    <nav>
                      <ul className="navigation">
                        <li><a href="#game">GAMING</a></li>
                        <li><a href="/#">TOKENOMICS</a></li>
                        <li><a href="assets/documents/WPaper.pdf">WHITEPAPER</a></li>
                        <li><a href="/#">ROADMAP</a></li>
                        <li><a href="/#">CONTACT</a></li>
                        <li><a href="/#">FAQ</a></li>
                      </ul>
                      <div className="footer-search">
                        <form>
                          <input type="text" placeholder="Find Here Now" />
                          <button><i className="fa-solid fa-magnifying-glass" /></button>
                        </form>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-quick-link-wrap">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-8">
                  <div className="quick-link-list">
                    <ul>
                      <li><a href="/#faq" >FAQ</a></li>
                      <li><a href="/policy" target="_blank">Privacy Policy</a></li>
                      <li><a href="assets/documents/WPaper.pdf">WhitePaper</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 col-md-4">
                  <div className="footer-social">
                    <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/metarealfights">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/MetaRealFights">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://discord.gg/u3e2BF5jTs">
                      <i className="fab fa-discord"></i>
                    </a>
                  </li>
                </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="copyright-text text-center">
                  <p>Copyright © 2022 All Rights Reserved By <span>MetaRealFights</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer
