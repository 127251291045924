import React from 'react';
import { Modal } from 'react-bootstrap';


class Brand extends React.Component {

  constructor(props){
      super(props);
      this.state = {
          show: false,
          modal: false
      }
  }

  handleShow(){
      this.setState({show: true})
      window.dotq = window.dotq || [];
      window.dotq.push({
          'projectId': '10000',
          'properties': {
              'pixelId': '10176003',
              'userEmail': '<email_address>',
              'qstrings': {
                  'et': 'custom',
                  'ec':'token'
              }
          } 
      });
      console.log(window.dotq)
  }

  fireMintButton(){
      window.dotq = window.dotq || [];
      window.dotq.push({
          'projectId': '10000',
          'properties': {
              'pixelId': '10176003',
              'userEmail': '<email_address>',
              'qstrings': {
                  'et': 'custom',
                  'ec':'mint'
              }
          }
      });

      console.log(window.dotq)
  }

  openModal(){
      this.setState({modal:true})
      window.dotq = window.dotq || [];
      window.dotq.push({
          'projectId': '10000',
          'properties': {
              'pixelId': '10176003',
              'userEmail': '<email_address>',
              'qstrings': {
                  'et': 'custom',
                  'ec':'discord'
              }
          } 
      });
      console.log(window.dotq)
  }
  render() {
  
  return (
	<div className="brand-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="brand-item-wrap">
                <ul>
                  <li><img src="assets/img/partners/4.png" alt="" /></li>
                  <li><img src="assets/img/partners/3.png" alt="" /></li>
                  <li><img src="assets/img/partners/1.png" alt="" /></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
}

export default Brand