import React from 'react';


function AboutArea() {
  
  return (
	<section id="game" className="about-area about-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-6">
              <div className="about-title">
                <h2 className="title">FIGHT 2 EARN</h2>
                <div className="about-content">
                  <h6 className="rotate-content">MRF NFT-GAMING</h6>
                  <h2 className="title">Join Our DISCORD <span><br/>To Join Our Community</span></h2>
                  <p>MetaRealFights is a Fight To Earn fighting game based on blockchain technology the characters will be NFTs signed and stored on the blockchain, each character will have different properties and looks, it is possible for the player to choose the outfit that suits him and fight with more style, players will be able to train their characters
                    in the training mode, access cards will be distributed and sold to users, this will increase the performance of fighters in order to reach the highest level and rank among the first on the ranking
                  </p>
                  
                    <h4 className="small-title">NFT Minting Price</h4>
                    <h6 className="price"><span> PRIVATE SALE NFT FIGHTER  = 0.15 ETH </span> / COMMING SOON </h6>
                    <h6 className="price"><span> PUBLIC SALE NFT FIGHTER  = 0.25 ETH </span> / COMMING SOON </h6>
                </div>
                  
                <div className="about-btn-wrap">
                 
                  <a href="https://discord.gg/u3e2BF5jTs" className="btn discord" target="_blank"><i className="fa-brands fa-discord" /> join discord now</a>
                  <a href="https://token.metarealfights.com" className="btn" target="_blank">Buy NFT "soon"</a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="about-img-wrap nftcard">
                <img src="assets/img/images/nftcard.png" alt="Nft Card MetaRealFights " />
              </div>
            </div>
          </div>
          <div className="s-about-wrap">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-6">
                <div className="s-about-title">
                  <h2 className="title">UNLEASH THE FIGHTER <span>INSIDE</span></h2>
                </div>
                <div className="s-about-content">
                  <p>Gaming is our passion. That's why we developed MetaRealFights, a multiplayer FightToEarn game with NFT-Skins and weapons in an open world interactive map. Each character in the game has a unique skill set and hidden abilities unlocked by completing specific challenges during gameplay. As a player, you have the ability to 1v1, 5v5, join tournaments, and create value by forging , lending and holding MRF NFTs.</p>
                  <p>Our vision is to develop modern high-end graphics games. Through this development, we aim to increase the value of our token ($MRF) MetaRealFights for the community. The games will be integrated into an NFT digital art marketplace through a decentralized ecosystem. All in-game items will be NFTs available in-game or options for sale on the open market, creating value for every penny spent. For more information, please visit our main website page and WHITE PAPER</p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <div className="s-about-img">

                  <img src="assets/img/images/vrfight2.png" alt="Vr fighting Metaverse" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default AboutArea