import React from 'react';
import { Link } from 'react-router-dom';
import PageNotFound from '../assets/img/images/404.png';
class NotFoundPage extends React.Component{
    render(){
        return <section className="404-section">
            <img src={PageNotFound}  />
            <p style={{textAlign:"center"}}>
                    <div className="banner-btn-wrap">
                      <a href="/#" className="btn" data-animation="fadeInLeft" data-delay=".8s" data-duration="2s" >
                        Marketplace...
                      </a>
                      <a href="/#" className="btn"  data-animation="fadeInRight" data-delay=".8s" data-duration="2s" onClick={() => this.handleShow() }>
                        buy token
                      </a>
                      <a href="/#" className="btn"  data-animation="fadeInRight" data-delay=".8s" data-duration="2s" onClick={() => this.openModal() }>
                        Twitter/Insta
                      </a>
                  </div>
            </p>
          </section>;
    }
}
export default NotFoundPage;