import React, { Component }  from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Policy from './pages/TermsPolicy';
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';
import ReactGA from 'react-ga4';
import NotFoundPage from './pages/NotFoundPage.jsx';
import CookieConsent from 'react-cookie-consent';






class App extends Component {

  
  render() {
    ReactGA.initialize("G-J320Z7C70S");
  return (
    <div className="App">
        <Header />
        <Router>
         <Switch>

         <ScrollToTopRoute exact={true} path="/">
            <Home/>
         </ScrollToTopRoute>
         <Route path="/policy" component={Policy} />
         <Route path="/404" component={NotFoundPage} />
         <Redirect to="/404" />
         
         </Switch>
         
        </Router>
        <CookieConsent 
          debug={true}
          location="bottom"
          style={{ background: '#000'}}
          buttonStyle={{ background: '#FF901B'}}
          expires={365}
          >
          This site uses cookies. See our <a href="/policy">privacy policy</a> for more
        </CookieConsent>
        
        <Footer/>
     
    </div>
  );
}
}
export default App;


