import React from 'react'
import Tokenomics from './Tokenomics'

function Token() {
  return (
	<section  id="token-allocations"className="token-area pt-115 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb-60">
                <h2 className="title"><span>MRF </span>TOKEN GIVES A BIG PUNCH</h2>
              </div>
            </div>
          </div>
          <div className="token-box-wrap">
            <div className="row align-items-end">
              <div className="col-lg-6">
                <div className="token-content mb-65">
                  <div className="token-icon mb-20">
                    <img src="assets/img/icon/token_icon.png" alt="" />
                  </div>
                  <h2 className="title">Token Allocations</h2>
                  <p>$MRF is a token utlity and governance token of the MetaRealFights ecosystem</p>
                  <a href="/#tokenomics" rel="noreferrer">Check our Tokenomics</a>
                </div>
                <div className="token-img text-center">
                  <img src="assets/img/images/token-img.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="token-item mb-20">
                  <div className="token-content-wrap">
                    <h2 className="title">total <span>41%</span></h2>
                  </div>
                  <ul className="token-list">
                    <li>6% Marketing </li>
                    <li>20% Development</li>
                    <li>15% Team</li>
                  </ul>
                </div>
                <div className="token-item mb-20">
                  <div className="token-content-wrap">
                    <h2 className="title">total <span>16%</span></h2>
                  </div>
                  <ul className="token-list">
                    <li></li>
                    <li>10% Private Sale</li>
                    <li>6% Public Sale</li>
                  </ul>
                </div>
                <div className="token-item mb-20">
                  <div className="token-content-wrap">
                    <h2 className="title">total <span>5%</span></h2>
                  </div>
                  <ul className="token-list">
                    <li></li>
                    <li>5% Advisors</li>
                    <li></li>
                  </ul>
                </div>
                <div className="token-item mb-20">
                  <div className="token-content-wrap">
                    <h2 className="title">total <span>10%</span></h2>
                  </div>
                  <ul className="token-list">
                    <li>5% Liquidity</li>
                    <li>5% Treasure</li>
                    <li></li>
                  </ul>
                </div>
                <div className="token-item mb-20">
                  <div className="token-content-wrap">
                    <h2 className="title">total <span>28%</span></h2>
                  </div>
                  <ul className="token-list">
                    <li>27% Staking/In-game Rewards</li>
                    <li>1% Airdrop</li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Token